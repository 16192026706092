<template>
  <article class="ep__candidate">
    <div class="ep__mugShot">
      <img
        class="ep__mugShot__image"
        :alt="'Photo of ' + name"
        :src="imageSrc"
      />
    </div>
    <div class="ep__candidate__info">
      <h2 class="ep__candidate__name">{{ name }}</h2>
      <p>{{ statement }}</p>
    </div>
  </article>
</template>

<script>
export default {
  name: "candidate",
  props: {
    name: String,
    statement: String,
    photo_filename: String
  },
  computed: {
   imageSrc() {
   return process.env.VUE_APP_API_STORAGE + this.photo_filename
   }
  },
}; 
</script>

<style scoped lang="scss">
@import "@/styles/vars.scss";
#score-election-app {
  .ep__candidate {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1em;

    .ep__candidate__info {
      flex: 66.66666%;
      max-width: 66.66666%;
      padding-left: 15px;
    }
    .ep__mugShot {
      flex: 33.33333%;
      max-width: 33.33333%;
    }
  }
}
</style>
