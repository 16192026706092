<template>
  <div class="ep__page ep__page--candidates">
  	<Navigation />
    <h1>Your candidates</h1>
    <hr>
    <p>There are ?? candidates looking for your vote. Our Scheme rules dictate that we use the 'first past the post' election system.</p>
    <p>Quite simply this means that the candidates with the most votes will be elected.</p>
    <p>Below you will find details of the candidates.</p>
    <div class="ep__candidates">
      <Candidate
      	v-for="candidate in electionData.candidates"
      	:name="candidate.name"
      	:statement="candidate.statement"
      	:photo_filename="candidate.photo_filename"
      >
      </Candidate>
    </div>
    <div class="ep__next-prev">
      <router-link to="/info"><button class="ep__btn ep__btn-secondary"><img class="ep__icon ep__icon--reversed" src="@/assets/icon--go.svg" alt="Icon go"> Back</button></router-link>
      <router-link to="/vote"><button class="ep__btn ep__btn-primary">Next <img class="ep__icon" src="@/assets/icon--go.svg" alt="Icon go"></button></router-link>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Navigation from '@/components/Navigation.vue'
  import Candidate from '@/components/Candidate.vue'

  export default {
    components: {
      Navigation,
      Candidate
    },
    props: {
    	electionData: Object
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/vars.scss";
  #score-election-app {
    .ep__candidates {
      margin-top: 2em;
    }
  }
</style>
